body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -------------------
NAVBAR/HEADER STYLES
------------------- */

.header {
  padding: 12px;
  color: #FDFFFF;
  width: calc(100% - 24px);
  background: rgb(30,51,141);
  background: linear-gradient(90deg, rgba(30,51,141,1) 0%, rgba(84,100,169,1) 100%);
}

.header-container {
  margin: auto;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.header-text {
  font-size: 30px;
}

.header-container > * {
  margin: 0;
}

.logo {
  height: 40px;
  margin-right: 12px;
}

.navOption {
  height: 100%;
  padding: 0 10px;
  line-height: 40px;
  margin-left: 10px;
}

.navOption:hover {
  background-color: #142363;
}

.active {
  background-color: #101C4F;
}

#algoSelectBox{
  background-color: rgba(255, 0, 0, 0);
  color: #FDFFFF;
  border: 0;
  padding: 0 2px;
  height: 100%;
  font-size: 15px;
}

#algoSelectBox .dropped {
  background-color:#142363;
}

/* -------------------
GRID STYLES
------------------- */

.grid {
  margin: 0;
  background-color: #FDFFFF;
  width: 100%;
  height: calc(100vh - 64px);
}

/* -------------------
CELL STYLES
------------------- */
.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell {
  height: 30px;
  width: 30px;
  background-color: #7881A7;
  margin: 0.5px;
  cursor: pointer;
}

.start {
  background-color: green;
  animation-name: cellAnimate;
  animation-duration: 0.15s;
}

.end {
  background-color: red;
  animation-name: cellAnimate;
  animation-duration: 0.15s;
}

.visited {
  background-color: #4c5891;
  animation-name: cellAnimate;
  animation-duration: 0.15s;
}

.wall {
  background-color: rgb(29, 29, 29);
  animation-name: cellAnimate;
  animation-duration: 0.15s;
}

.highlight {
  background-color: #063233;
  animation-name: pathAnimate;
  animation-duration: 1s;
}

@keyframes cellAnimate {
  0% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);

  }
}

@keyframes pathAnimate {
  0% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);

  }
}